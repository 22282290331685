<template>
  <TTView>
    <VRow>
      <VCol>
        <h1>Classes</h1>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <h2>Flex</h2>
      </VCol>
      <VCol align="right">
        <pre><code class="pa-2 rounded-lg">{{ wrapperClasses }}</code></pre>
      </VCol>
    </VRow>

    <VRow>
      <VCol align="left">
        <div>
          <VSlider
            v-model="blocksCount"
            label="Blocks count"
            step="1"
            thumb-label
            max="60"
          />

          <VSlider
            v-model="gap"
            label="Gap (0 – 16)"
            step="1"
            thumb-label
            ticks
            max="16"
          />

          <VSlider
            v-model="rowGap"
            label="Row gap (0 – 16)"
            step="1"
            thumb-label
            ticks
            max="16"
          />

          <VSlider
            v-model="columnGap"
            label="Column gap (0 – 16)"
            step="1"
            thumb-label
            ticks
            max="16"
          />
        </div>
      </VCol>
    </VRow>

    <VRow>
      <VCol align="center">
        <div
          class="wrapper tt-flex flex-wrap tt-light-mono-8 pa-2 rounded-lg"
          :class="wrapperClasses"
        >
          <div
            v-for="index in blocksCount"
            :key="index"
            :class="blockClasses"
          >
            {{ index }}
          </div>
        </div>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'Classes',
  data() {
    return {
      blocksCount: 8,
      gap: 4,
      rowGap: 0,
      columnGap: 0,
    };
  },
  computed: {
    wrapperClasses() {
      return `tt-flex-gap-${this.gap} tt-flex-column-gap-${this.columnGap} tt-flex-row-gap-${this.rowGap}`;
    },
    blockClasses() {
      return 'block rounded-lg tt-light-mono-0 tt-light-mono-24--text'
        + ' font-weight-bold tt-flex justify-center align-center';
    },
  },
};
</script>

<style lang="scss" scoped>
  .wrapper {
    border: 1px solid #ededed;
    transition: gap .3s ease-in-out;
  }

  .block {
    border: 1px solid #ededed;
    flex: 1 0 100px;
    min-height: 50px;
  }
</style>
